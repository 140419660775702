<template>
  <div>
    <div>
      Question: <flux-input v-model:modelValue="item.text"></flux-input>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BooleanItem } from "@/libraries/questionnaires/item";

defineProps<{
  item: BooleanItem;
}>();
</script>
