<template>
  <div
    class="grid gap-1 text-sm"
    :class="
      allowOverflow === true
        ? 'grid-cols-[20px,max-content]'
        : 'grid-cols-[20px,auto]'
    "
  >
    <div class="place-self-center">
      <slot name="icon" />
    </div>
    <div class="text-sm font-medium">
      <slot name="name" />
    </div>
    <div class="col-start-2" v-if="properties.length > 0">
      <div
        class="flex flex-wrap gap-1 text-xs font-normal text-gray-800 dark:text-neutral-300"
      >
        <template
          v-for="(property, index) in properties"
          :key="index + property"
        >
          <span v-if="index > 0">·</span>
          <span>{{ property }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
withDefaults(defineProps<{ properties: string[]; allowOverflow?: boolean }>(), {
  allowOverflow: false,
});
</script>
