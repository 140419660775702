<template>
  <span class="relative inline-flex">
    <slot></slot>
    <span
      v-if="!hidden"
      :class="[
        'absolute block w-2 min-w-min rounded-full text-xs',
        'text-white ring-1 ring-white dark:ring-neutral-900',
        color,
        icon
          ? '-right-5 -top-3 h-4 px-1 py-0.5'
          : content
            ? '-right-4 -top-2 h-4 px-1'
            : '-right-2 -top-1 h-2',
      ]"
    >
      <i v-if="icon" :class="icon"></i>
      {{ content }}
    </span>
  </span>
</template>

<script lang="ts" setup>
const {
  color = "bg-orange-600",
  content,
  icon,
  hidden = false,
} = defineProps<{
  color: string;
  content?: string;
  icon?: string;
  hidden?: boolean;
}>();
</script>
