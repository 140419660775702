<template>
  <div class="flex flex-col items-center gap-4">
    <slot name="image"></slot>

    <slot name="text">
      <span
        class="text-sm font-medium text-gray-900 dark:text-white"
        v-if="text"
        >{{ text }}</span
      >
    </slot>
    <span class="text-sm text-gray-500 dark:text-neutral-300" v-if="subText">{{
      subText
    }}</span>
    <slot name="button"></slot>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  text?: string;
  subText?: string;
}>();
</script>
