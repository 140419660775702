import { handledZodParse } from "@/libraries/utils/errorHandling";
import { appointmentStatus } from "@/models/Appointment";
import {
  useQueryClient,
  useQuery,
  useMutation,
  useQueries,
} from "@tanstack/vue-query";
import { apiClient } from "@/libraries/utils/axios";
import { Ref, unref, computed } from "vue";
import { z } from "zod";
import { invoiceTypes } from "@/apis/patients/invoices";
import { healthcareProfessionalSchema } from "@/models/HealthcareProfessional";
import { nationalIdentityNumberType } from "@/models/Forms";
import { payerInsurerSchema } from "@/models/PayerInsurer";
import { MaybeRef } from "@tanstack/vue-query/build/legacy/types";
import { patientLabelSchema } from "@/models/PatientLabel";
import { carePlanSchema } from "@/libraries/repositories/CarePlan/CarePlan";
import { emailSentScheme } from "@/models/EmailSent";
import { referralInvoiceSettingsSchema } from "@/libraries/repositories/Referral/ReferralRepository";
import { documentSchema } from "@/models/Document";
import { healthcareProfessionalTypes } from "@/models/HealthcareProfessionalType";
import { phoneNumberTypes } from "@/models/PhoneNumber";
import { addressSchema } from "@/models/Address";
import { hasPermission } from "@/libraries/utils/hasPermission";
import { emailAddressTypes } from "@/models/EmailAddress";
import { recurringPaymentMandateSchema } from "@/models/RecurringPaymentMandate";
import { locationIndications } from "@/models/Referral";

export async function getPatient(
  zisNumber: MaybeRef<number>,
): Promise<Patient> {
  const result = await apiClient.get(`/patients/${unref(zisNumber)}`);
  return handledZodParse({
    schema: patientScheme,
    input: result.data,
  });
}

export const referralSchema = z.object({
  id: z.number(),
  patient_zis_number: z.number(),
  AGB_code: z.string().optional(),
  referred_at: z
    .string()
    .optional()
    .transform((s) => (s ? new Date(s) : undefined)),
  specialism_code: z.string().optional(),
  is_self_referral: z.boolean(),
  hcp_diagnosis: z.string(),
  diagnosis_code: z.string().optional(),
  referrer_diagnosis_code: z.string().optional(),
  healthcare_professional_type: z.enum(healthcareProfessionalTypes),
  deleted_at: z
    .string()
    .optional()
    .transform((s): Date | undefined =>
      s === undefined ? undefined : new Date(s),
    ),
  closed_at: z
    .string()
    .optional()
    .transform((s): Date | undefined =>
      s === undefined ? undefined : new Date(s),
    ),
  hcp_lpdf_id: z.number().optional(),
  hcp_lpdf_name: z.string().optional(),
  import_data: z.any(),
  is_chronic: z.boolean().optional(),
  chronic_periods: z.any(),
  referral_information: z.string().optional(),
  is_declarable: z.boolean().optional(),
  indication_accident: z.enum(["O", "J", "N"]).optional(),
  external_treatment_count: z.number().optional(),
  location_indication: z.enum(locationIndications).optional(),
  default_csi_code: z.string().optional(),
  machtiging_code: z.string().optional(),
  care_plans: z.array(carePlanSchema),
  qdna_queues: z.array(z.any()),
  qualiview_activities: z.array(z.any()),
  consents: z.array(z.any()),
  main_therapist_id: z.number().optional(),
  treatment_count: z.number(),
  gds_uuid: z.string().optional(),
  start_date: z
    .string()
    .optional()
    .transform((s): Date | undefined =>
      s === undefined ? undefined : new Date(s),
    ),
  is_red_flagged: z.boolean().optional(),
  invoice_settings: referralInvoiceSettingsSchema.optional(),
  is_draft: z.boolean().optional(),
  created_at: z.string().transform((s) => new Date(s)),
  updated_at: z.string().transform((s) => new Date(s)),
});
export type PatientReferral = z.infer<typeof referralSchema>;

export const patientScheme = z.object({
  zis_number: z.number(),
  gender: z.optional(z.enum(["M", "F", "UN", "UNK"])),
  initials: z.optional(z.string()),
  first_names: z.optional(z.string()),
  surname: z.optional(z.string()),
  date_of_birth: z.optional(z.string().transform((x) => new Date(x))),
  date_of_death: z.optional(z.string().transform((x) => new Date(x))),
  deceased: z.boolean(),
  surname_prefix: z.optional(z.string()),
  maiden_name: z.optional(z.string()),
  maiden_name_prefix: z.optional(z.string()),
  nickname: z.optional(z.string()),
  has_bsn: z.boolean(),
  multiple_birth: z.boolean(),
  deleted_at: z
    .string()
    .optional()
    .transform((s): Date | undefined =>
      s === undefined ? undefined : new Date(s),
    ),
  email_addresses: z.array(
    z.object({
      id: z.number(),
      patient_zis_number: z.number(),
      email_address: z.string(),
      email_address_type: z.enum(emailAddressTypes),
      created_at: z.string().transform((value) => new Date(value)),
      updated_at: z.string().transform((value) => new Date(value)),
      preferred: z.boolean(),
    }),
  ),
  phone_numbers: z.array(
    z.object({
      id: z.number(),
      phone_number: z.string(),
      phone_number_type: z.enum(phoneNumberTypes),
      created_at: z.string().transform((s) => new Date(s)),
      updated_at: z.string().transform((s) => new Date(s)),
      preferred: z.boolean(),
    }),
  ),
  addresses: z.array(addressSchema),
  documents: z.array(documentSchema),
  appointments: z
    .array(
      z.object({
        id: z.number(),
        status: z.enum(appointmentStatus),
        description: z.optional(z.string()),
        start: z.string().transform((s) => new Date(s)),
        end: z.string().transform((s) => new Date(s)),
        created_at: z.string().transform((s) => new Date(s)),
        updated_at: z.string().transform((s) => new Date(s)),
        deleted_at: z
          .optional(z.string())
          .transform((s) => (s ? new Date(s) : undefined)),
        referral_id: z.optional(z.number()),
        appointment_type_id: z.optional(z.number()),
        private: z.optional(z.boolean()),
        uuid: z.string().uuid(),
        participants: z.array(z.any()),
        email_sents: z.array(emailSentScheme),
        locked: z.optional(z.boolean()),
        repeating_group: z
          .object({
            uuid: z.string(),
            interval: z.enum(["week"]),
            interval_multiplier: z.number(),
          })
          .optional(),
      }),
    )
    .transform((x) => x.sort((a, b) => b.start.getTime() - a.start.getTime())),
  wallet_uuid: z.optional(z.string()),
  suppressed_email_types: z.array(z.any()),
  languages: z.array(
    z.object({
      code: z.string(),
      communication_language_nl: z.string(),
    }),
  ),
  contacts: z.array(
    z.object({
      id: z.number(),
      first_names: z.optional(z.string()),
      surname: z.optional(z.string()),
      surname_prefix: z.optional(z.string()),
      maiden_name: z.optional(z.string()),
      maiden_name_prefix: z.optional(z.string()),
      relationship: z.string(),
      role: z.string(),
      intials: z.optional(z.string()),
      fullname: z.optional(z.string()),
      is_payer: z.boolean(),
      email_addresses: z.array(z.any()),
      phone_numbers: z.array(z.any()),
    }),
  ),
  healthcare_professionals: z.array(healthcareProfessionalSchema),
  payer_insurers: z.array(payerInsurerSchema),
  uuid: z.string().uuid().optional(),
  company_division_id: z.optional(z.string().uuid()),
  employee_number: z.optional(z.string()),
  nationalities: z.array(z.object({ id: z.number(), description: z.string() })),
  invoice_settings: z
    .object({
      default_invoice_type: z.enum(invoiceTypes).optional(),
    })
    .optional(),
  photo_filename: z.optional(z.string()),
  referrals: z.array(referralSchema),
  labels: z.array(patientLabelSchema).optional(),
  inactive_at: z.optional(
    z.string().transform((x) => (x ? new Date(x) : undefined)),
  ),
  national_identity_number_type: z.enum(nationalIdentityNumberType).optional(),
  patient_title_uuid: z.string().optional(),
  custom_short_title: z.string().optional(),
  custom_long_title: z.string().optional(),
  salutation_template_uuid: z.string().optional(),
  custom_salutation: z.string().optional(),
  patient_treatment_counts: z.array(z.any()).optional(),
  recurring_payment_manadates: z
    .array(recurringPaymentMandateSchema)
    .optional(),
});

export type Patient = z.infer<typeof patientScheme>;
export type PatientAppointment = z.infer<
  typeof patientScheme
>["appointments"][number];

const gcTime = 1000 * 60 * 5;
const staleTime = 1000 * 60 * 60 * 24;

export function usePatientPrefetch() {
  const queryClient = useQueryClient();
  const prefetchPatient = async (zisNumber: number) => {
    await queryClient.prefetchQuery({
      gcTime,
      staleTime,
      queryKey: ["patient", zisNumber],
      queryFn: () => getPatient(zisNumber),
    });
  };

  return { prefetchPatient };
}

export function usePatient(zisNumber: Ref<number>) {
  const queryClient = useQueryClient();
  function invalidatePatient(zisNumber: number) {
    return queryClient.invalidateQueries({ queryKey: ["patient", zisNumber] });
  }

  const query = useQuery({
    gcTime,
    staleTime,
    queryKey: ["patient", zisNumber] as const,
    queryFn: ({ queryKey }) => getPatient(queryKey[1]),
    enabled: hasPermission("view-patient"),
  });

  return { ...query, invalidatePatient };
}

export function usePatients(zisNumbers: Ref<number[]>) {
  const query = useQueries({
    queries: computed(() =>
      zisNumbers.value.map((zisNumber) => ({
        gcTime,
        staleTime,
        queryKey: ["patient", zisNumber] as const,
        queryFn: () => getPatient(zisNumber),
        enabled: hasPermission("view-patient"),
      })),
    ),
  });
  const hasUnplannablePatient = computed(() =>
    query.value.some((patient) =>
      patient.data?.labels?.some((label) => label.unplannable),
    ),
  );

  return { ...query, hasUnplannablePatient };
}

export function useUpdatePatient(zisNumber: Ref<number>) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (payload: unknown) => mutatePatient(zisNumber.value, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["patient", zisNumber] });
    },
  });

  return {
    updatePatient: (payload: unknown) => mutation.mutateAsync(payload, {}),
    isLoading: mutation.isPending,
  };
}

async function mutatePatient(zisNumber: number, payload: unknown) {
  await apiClient.patch("/patients/:zis_number", payload, {
    params: {
      zis_number: zisNumber,
    },
  });
}

export function useInvalidatePatient() {
  const queryClient = useQueryClient();
  function invalidatePatient(zisNumber: number) {
    return queryClient.invalidateQueries({ queryKey: ["patient", zisNumber] });
  }
  return { invalidatePatient };
}
