<template>
  <div :class="['flex flex-col space-y-1', span ? span : 'col-span-12']">
    <div>
      <label
        class="text-sm text-gray-800/70 dark:text-neutral-300"
        v-if="label || title"
      >
        {{ label || title }}
        <span class="text-red-500" v-if="required"> * </span>
      </label>
      <span class="select-none text-transparent" v-else>_</span>
      <InfoBox v-if="info">{{ info }}</InfoBox>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import InfoBox from "../InfoBox.vue";

withDefaults(
  defineProps<{
    span?: string;
    label?: string;
    title?: string;
    required?: boolean;
    info?: string;
  }>(),
  {
    required: false,
  },
);
</script>
