export interface DCSPH {
  [group: number]: {
    types: string[];
    children: {
      [group: number]: string;
    };
  };
}

export type DCSPHDropdown = {
  value: string;
  label: string;
  children: {
    value: string;
    label: string;
    disabled: boolean;
  }[];
}[];

export const dcsph_position_1_2: DCSPH = {
  1: {
    types: ["Hoofd", "Hals"],
    children: {
      0: "Achterzijde hoofd",
      1: "Aangezicht",
      2: "Regio buccalis inclusief de kaak",
      3: "Regio cervicalis (oppervlakkige weke delen)",
      9: "Gecombineerd",
    },
  },
  2: {
    types: ["Thorax", "Buik", "Inwendige Organen"],
    children: {
      0: "Regio thoracalis anterior (oppervlakkige weke delen)",
      1: "Regio thoracalis dorsalis (oppervlakkige weke delen)",
      2: "Ribben I Sternum",
      3: "Regio abdominalis (oppervlakkige weke delen)",
      4: "Regio lumbalis (oppervlakkige weke delen)",
      5: "Inwendige organen thorax",
      6: "Inwendige organen abdomen",
      9: "Gecombineerd",
    },
  },
  3: {
    types: ["Wervelkolom"],
    children: {
      0: "Cervicale wervelkolom",
      1: "Cervico-thoracale wervelkolom",
      2: "Thoracale wervelkolom",
      3: "Thoraco-lumbale wervelkolom",
      4: "Lumbale wervelkolom",
      5: "Lumbo-sacrale wervelkolom",
      6: "Sacrum en S.I. gewrichten",
      7: "Coccygis",
      9: "Gecombineerd / totale wervelkolom",
    },
  },
  4: {
    types: ["Schouder", "Bovenarm"],
    children: {
      0: "Art. humeri",
      1: "Regio clavicularis (incl. aangrenzende gewrichten",
      2: "Regio scapularis",
      3: "Bovenarmregio",
      9: "Gecombineerd",
    },
  },
  5: {
    types: ["Elleboog", "Onderarm", "Hand"],
    children: {
      0: "Art. cubiti (inclusief weke delen)",
      1: "Onderarmregio",
      2: "Handwortel / polsgewricht (inclusief weke delen)",
      3: "Middenhandregio (inclusief weke delen)",
      4: "Vingers",
      5: "Duim",
      9: "Gecombineerd",
    },
  },
  6: {
    types: ["Bekken", "Bovenbeen"],
    children: {
      0: "Bekkenregio",
      1: "Liesregio",
      2: "Art. coxae (inclusief weke delen)",
      3: "Bovenbeenregio (inclusief weke delen)",
      4: "Bekkenbodemregio (incl organen kleine bekken)",
      9: "Gecombineerd",
    },
  },
  7: {
    types: ["Knie", "Onderbeen", "Voet"],
    children: {
      0: "Art. genus (inclusief patella en weke delen)",
      1: "Onderbeenregio",
      2: "Bovenste spronggewricht (inclusief weke delen)",
      3: "Onderste spronggewricht (inclusief weke delen)",
      4: "Voetwortel",
      5: "Middenvoet",
      6: "Voorvoet (tenen)",
      9: "Gecombineerd",
    },
  },
  9: {
    types: ["Meer dan éen hoofdgroep"],
    children: {
      0: "Één lichaamszijde",
      1: "Bovenste lichaamshelft",
      2: "Onderste lichaamshelft",
      3: "Gegeneraliseerd",
      4: "Meer lokalisaties",
    },
  },
};

export const dcsph_position_3_4: DCSPH = {
  0: {
    types: ["Chirurgie bewegingsapparaat"],
    children: {
      0: "Amputatie",
      1: "Gewrichten, uitgezonderd wervelkolom, meniscectomie, synovectomie",
      2: "Botten, uitgezonderd wervelkolom",
      3: "Meniscectomie, synovectomie",
      4: "Pees, spier, ligament",
      5: "Wervelkolom",
      6: "Verwijderde osteosynthese materiaal",
      8: "Postoperatieve contractuur / atrofie",
      9: "Overige chirurgie van het bewegingsapparaat (incl. nieuwvormingen)",
    },
  },
  1: {
    types: ["Orthopedische aandoeningen zonder chirurgie"],
    children: {
      0: "Aseptische botnecrose",
      1: "Afwijkingen wervelkolom / bekken",
      2: "Skeletafwijkingen (aangeboren)",
      3: "Ossificatiestoornis",
      4: "Ontstekingen / nieuwvormingen in het skelet",
      5: "Pseudo-arthrose / epiphysiolysis / apofysitiden",
      6: "Standsafwijkingen extremiteiten",
      7: "Afwijkingen gewrichten, uitgezonderd wervelkolom / bekken",
      8: "Overige orthopedische aandoeningen zonder chirurgie",
      9: "Dupuytren",
    },
  },
  2: {
    types: ["Surmenage degeneratieve aandoeningen dystrofie"],
    children: {
      0: "Epicondylitis / tendinitis / tendovaginitis",
      1: "Bursitis (niet traumatisch) / capsulitis",
      2: "Chondropathie / arthropathie / meniscuslaesie",
      3: "Arthrose",
      4: "Osteoporose",
      5: "Syndroom van Costen",
      6: "Spier-, pees- en fascie aandoeningen",
      7: "Discusdegeneratie, coccygodynie / HNP",
      8: "Sudeckse a(dys)trofie",
    },
  },
  3: {
    types: [
      "Traumatische aandoenigen uitgezonderd chirurgie, neurotraumata en dwarslaesie",
    ],
    children: {
      1: "Gewrichtscontusie / -distorsie",
      2: "Luxatie (sub-)",
      3: "Spier-, peesruptuur / haematoom",
      4: "Hydrops / haemarthros / traumatisch oedeem",
      5: "Myositis ossificans / adhaesies / traumatische bursitis",
      6: "Fracturen",
      8: "Whiplash injury (nektrauma)",
      9: "Status na brandwonden",
    },
  },
  4: {
    types: ["Hart- vaat en lymfeaandoeingen (inclusief cardiochirurgie)"],
    children: {
      0: "Hartaandoeningen (niet genoemd onder 41 t/m 49)",
      1: "Myocard-infarct (AMI)",
      2: "Status na coronary artery bypassoperatie (CABG)",
      3: "Status na percutane transluminale coronair angioplastiek (PTCA)",
      4: "Status na hartklepoperatie",
      5: "Status na operatief gecorrigeerde congenitale afwijkingen",
      6: "Lymfevataandoeningen / oedeem",
      7: "Ulcus / decubitus / necrose",
      8: "Algemeen vaatlijden, circulatiestoornissen",
    },
  },
  5: {
    types: ["Long aandoeningen"],
    children: {
      0: "Cara",
      1: "Aangeboren afwijkingen tractus respiratorius",
      2: "Pneumothorax / longoedeem",
      3: "Luchtweginfecties",
      4: "COPD",
      5: "Emfyseem",
      6: "Interstitiële longaandoeningen incl. sarcoïdose",
    },
  },
  6: {
    types: [
      "Overige interne aandoeningen, nieuwvormingen, chirurgie niet bewegingsapparaat",
    ],
    children: {
      0: "Diabetes mellitus",
      1: "Immuniteitsstoornissen",
      2: "Spastisch colon",
      3: "COVID-19",
      4: "Adipositas",
      5: "Overige-, erfelijke aandoeningen",
      8: "Chirurgie niet bewegingsapparaat (niet cardiochirurgie)",
      9: "Nieuwvormingen zonder chirurgie",
    },
  },
  7: {
    types: ["Neurologische aandoeningen"],
    children: {
      0: "Perifere zenuwaandoening",
      1: "Cerebellaire aandoeningen / encephalopathieën",
      2: "Cerebrovasculair accident / centrale parese",
      3: "Multiple sclerose / ALS / spinale spieratrofie",
      4: "Parkinson / extrapyramidale aandoening",
      5: "HNP met radiculair syndroom",
      6: "Dwarslaesie (incl. traumatisch en partieel)",
      7: "Neurotraumata",
      8: "Overige neurologische aandoeningen / neuropathieën / ziekten van neurologische oorsprong",
      9: "Psychomotore retardatie / ontwikkelingsstoornissen",
    },
  },
  8: {
    types: ["Symptomatologie", "Psychosomatiek", "Urologie", "Gynaecologie"],
    children: {
      0: "Symptomatologie (nog zonder aanwijsbare pathologie)",
      1: "Psychosomatische aandoeningen",
      2: "Hyperventilatie zonder longpathologie",
      3: "proctologie (anus-endeldarm)",
      4: "MDL (maag-darm-lever)",
      5: "Seksuologie",
      6: "Urine incontinentie, incontinentia urinae",
      7: "Fecale incontinentie, incontinentia alvi",
      8: "Urologie",
      9: "Gynaecologie",
    },
  },
  9: {
    types: ["Reumatische aandoeningen", "Huidaandoeningen"],
    children: {
      0: "Reumatoïde arthritis, chronische reuma",
      1: "Juveniel reuma",
      2: "(Poly-) arthritis",
      3: "Spondylitis ankylopoetica / ankylose",
      4: "Overige reumatische- en collageenaandoeningen",
      5: "Littekenweefsel",
      6: "Sclerodermie",
      7: "Psoriasis",
      8: "Hyperhydrosis",
      9: "Overige huidaandoeningen",
    },
  },
};

// Based on Uniforme_DCSPH_lijst_per_1-1-2024_Versie_4.5_u1
export const combinations: { [key: string]: string[] } = {
  "10": ["20", "26", "33", "39", "46", "68", "70", "77", "78", "81"],
  "11": [
    "26",
    "33",
    "34",
    "39",
    "46",
    "68",
    "70",
    "71",
    "77",
    "78",
    "81",
    "95",
    "99",
  ],
  "12": [
    "00",
    "01",
    "02",
    "03",
    "04",
    "06",
    "08",
    "09",
    "12",
    "13",
    "14",
    "15",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "68",
    "70",
    "71",
    "77",
    "78",
    "81",
    "92",
    "95",
  ],
  "13": [
    "04",
    "08",
    "09",
    "20",
    "26",
    "33",
    "35",
    "39",
    "46",
    "68",
    "70",
    "71",
    "77",
    "78",
    "81",
    "95",
  ],
  "19": [
    "08",
    "12",
    "14",
    "17",
    "20",
    "26",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "68",
    "69",
    "70",
    "71",
    "77",
    "78",
    "79",
    "80",
    "81",
    "95",
    "99",
  ],
  "20": [
    "04",
    "08",
    "09",
    "20",
    "26",
    "33",
    "39",
    "46",
    "68",
    "70",
    "77",
    "78",
    "81",
    "95",
  ],
  "21": [
    "04",
    "08",
    "09",
    "20",
    "26",
    "33",
    "39",
    "46",
    "48",
    "68",
    "70",
    "77",
    "78",
    "81",
    "95",
  ],
  "22": [
    "00",
    "01",
    "02",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "17",
    "18",
    "21",
    "22",
    "24",
    "26",
    "31",
    "32",
    "34",
    "36",
    "46",
    "70",
    "77",
    "78",
    "81",
    "92",
  ],
  "23": [
    "04",
    "08",
    "09",
    "20",
    "26",
    "33",
    "34",
    "35",
    "39",
    "46",
    "70",
    "77",
    "78",
    "81",
    "95",
  ],
  "24": [
    "04",
    "08",
    "09",
    "20",
    "26",
    "33",
    "34",
    "35",
    "39",
    "46",
    "68",
    "70",
    "77",
    "78",
    "81",
    "95",
  ],
  "25": [
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "68",
    "70",
    "81",
    "82",
  ],
  "26": ["46", "62", "68", "81", "89"],
  "29": [
    "04",
    "08",
    "09",
    "26",
    "33",
    "34",
    "35",
    "39",
    "46",
    "48",
    "68",
    "69",
    "70",
    "78",
    "80",
    "81",
    "95",
  ],
  "30": [
    "04",
    "05",
    "06",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "18",
    "22",
    "23",
    "24",
    "26",
    "27",
    "31",
    "32",
    "33",
    "34",
    "36",
    "38",
    "70",
    "75",
    "76",
    "77",
    "78",
    "80",
    "81",
    "92",
  ],
  "31": [
    "04",
    "05",
    "06",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "18",
    "21",
    "22",
    "23",
    "24",
    "26",
    "27",
    "31",
    "32",
    "33",
    "34",
    "36",
    "38",
    "70",
    "75",
    "76",
    "77",
    "78",
    "80",
    "81",
    "92",
  ],
  "32": [
    "04",
    "05",
    "06",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "18",
    "21",
    "22",
    "23",
    "24",
    "26",
    "27",
    "31",
    "32",
    "33",
    "34",
    "36",
    "70",
    "75",
    "76",
    "77",
    "78",
    "80",
    "81",
    "92",
  ],
  "33": [
    "04",
    "05",
    "06",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "18",
    "21",
    "22",
    "23",
    "24",
    "26",
    "27",
    "31",
    "32",
    "33",
    "34",
    "36",
    "70",
    "75",
    "76",
    "77",
    "78",
    "80",
    "81",
    "92",
  ],
  "34": [
    "04",
    "05",
    "06",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "18",
    "21",
    "22",
    "23",
    "24",
    "26",
    "27",
    "31",
    "32",
    "33",
    "34",
    "36",
    "70",
    "75",
    "76",
    "77",
    "78",
    "80",
    "81",
    "92",
  ],
  "35": [
    "04",
    "05",
    "06",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "18",
    "21",
    "22",
    "23",
    "24",
    "26",
    "27",
    "31",
    "32",
    "33",
    "34",
    "36",
    "70",
    "75",
    "76",
    "77",
    "78",
    "80",
    "81",
    "92",
  ],
  "36": [
    "04",
    "05",
    "06",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "18",
    "21",
    "22",
    "23",
    "24",
    "26",
    "27",
    "31",
    "32",
    "33",
    "34",
    "36",
    "70",
    "75",
    "77",
    "78",
    "80",
    "81",
    "92",
  ],
  "37": [
    "04",
    "05",
    "06",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "18",
    "22",
    "23",
    "24",
    "26",
    "27",
    "31",
    "32",
    "34",
    "36",
    "70",
    "75",
    "77",
    "78",
    "80",
    "81",
    "92",
  ],
  "39": [
    "04",
    "05",
    "06",
    "08",
    "09",
    "11",
    "12",
    "13",
    "14",
    "15",
    "18",
    "21",
    "22",
    "23",
    "24",
    "26",
    "27",
    "31",
    "32",
    "33",
    "34",
    "36",
    "69",
    "70",
    "75",
    "77",
    "78",
    "80",
    "81",
    "88",
    "92",
    "93",
    "95",
  ],
  "40": [
    "00",
    "01",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "48",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
  ],
  "41": [
    "00",
    "01",
    "02",
    "03",
    "04",
    "06",
    "08",
    "09",
    "12",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "36",
    "46",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
  ],
  "42": [
    "00",
    "01",
    "02",
    "04",
    "06",
    "08",
    "09",
    "12",
    "14",
    "16",
    "18",
    "20",
    "24",
    "26",
    "28",
    "33",
    "34",
    "36",
    "46",
    "48",
    "70",
    "77",
    "78",
    "81",
    "95",
  ],
  "43": [
    "00",
    "01",
    "02",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "16",
    "18",
    "20",
    "24",
    "26",
    "28",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "70",
    "77",
    "78",
    "81",
    "95",
  ],
  "49": [
    "01",
    "02",
    "03",
    "04",
    "06",
    "08",
    "09",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "68",
    "69",
    "70",
    "77",
    "78",
    "80",
    "81",
    "92",
    "95",
    "99",
  ],
  "50": [
    "00",
    "01",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "48",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
  ],
  "51": [
    "00",
    "02",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "14",
    "16",
    "18",
    "20",
    "24",
    "26",
    "28",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "48",
    "68",
    "70",
    "77",
    "78",
    "81",
    "95",
    "99",
  ],
  "52": [
    "00",
    "01",
    "02",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "36",
    "39",
    "46",
    "48",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
  ],
  "53": [
    "00",
    "01",
    "02",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "36",
    "39",
    "46",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
  ],
  "54": [
    "00",
    "01",
    "02",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "36",
    "39",
    "46",
    "48",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
    "99",
  ],
  "55": [
    "00",
    "01",
    "02",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "36",
    "39",
    "46",
    "48",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
  ],
  "59": [
    "00",
    "01",
    "02",
    "03",
    "04",
    "06",
    "08",
    "09",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "48",
    "68",
    "69",
    "70",
    "77",
    "78",
    "80",
    "81",
    "92",
    "95",
    "98",
    "99",
  ],
  "60": [
    "00",
    "01",
    "02",
    "04",
    "06",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "18",
    "20",
    "22",
    "24",
    "26",
    "31",
    "33",
    "35",
    "36",
    "39",
    "46",
    "48",
    "68",
    "70",
    "77",
    "78",
    "81",
    "86",
    "88",
    "89",
    "99",
  ],
  "61": [
    "04",
    "08",
    "09",
    "18",
    "20",
    "26",
    "33",
    "39",
    "46",
    "48",
    "70",
    "77",
    "78",
    "81",
    "99",
  ],
  "62": [
    "00",
    "01",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "46",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
  ],
  "63": [
    "00",
    "02",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "14",
    "16",
    "18",
    "20",
    "24",
    "26",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "48",
    "68",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
    "99",
  ],
  "64": [
    "10",
    "22",
    "26",
    "27",
    "31",
    "32",
    "33",
    "34",
    "36",
    "68",
    "69",
    "70",
    "71",
    "72",
    "73",
    "74",
    "75",
    "76",
    "77",
    "78",
    "79",
    "80",
    "81",
    "83",
    "84",
    "85",
    "86",
    "87",
    "88",
    "89",
    "95",
    "96",
    "99",
  ],
  "69": [
    "02",
    "04",
    "06",
    "08",
    "09",
    "12",
    "13",
    "14",
    "16",
    "18",
    "20",
    "21",
    "22",
    "24",
    "26",
    "31",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "48",
    "68",
    "69",
    "70",
    "77",
    "78",
    "80",
    "81",
    "92",
    "95",
    "99",
  ],
  "70": [
    "00",
    "01",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "70",
    "77",
    "78",
    "81",
    "90",
    "92",
    "95",
  ],
  "71": [
    "00",
    "02",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "16",
    "18",
    "20",
    "24",
    "26",
    "28",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "47",
    "48",
    "68",
    "70",
    "77",
    "78",
    "81",
    "95",
    "99",
  ],
  "72": [
    "00",
    "01",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "48",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
  ],
  "73": [
    "00",
    "01",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "48",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
  ],
  "74": [
    "00",
    "01",
    "02",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "36",
    "39",
    "46",
    "48",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
  ],
  "75": [
    "00",
    "01",
    "02",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "36",
    "39",
    "46",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
  ],
  "76": [
    "00",
    "01",
    "02",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "36",
    "39",
    "46",
    "48",
    "70",
    "77",
    "78",
    "81",
    "92",
    "95",
  ],
  "79": [
    "01",
    "02",
    "03",
    "04",
    "06",
    "08",
    "09",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "48",
    "68",
    "69",
    "70",
    "77",
    "78",
    "80",
    "81",
    "92",
    "95",
    "98",
    "99",
  ],
  "90": ["70", "71", "72", "77", "78", "81"],
  "91": ["23", "47", "70", "71", "77", "78", "79", "80", "81", "95", "99"],
  "92": [
    "00",
    "10",
    "15",
    "23",
    "47",
    "48",
    "70",
    "71",
    "77",
    "78",
    "79",
    "80",
    "81",
    "95",
    "99",
  ],
  "93": [
    "17",
    "48",
    "61",
    "63",
    "65",
    "71",
    "72",
    "73",
    "74",
    "78",
    "79",
    "80",
    "81",
    "82",
    "90",
    "91",
    "94",
    "96",
    "97",
    "99",
  ],
  "94": [
    "00",
    "01",
    "02",
    "03",
    "04",
    "06",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "20",
    "21",
    "22",
    "23",
    "24",
    "26",
    "28",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "39",
    "46",
    "47",
    "48",
    "68",
    "69",
    "70",
    "74",
    "77",
    "78",
    "80",
    "81",
    "92",
    "95",
    "98",
    "99",
  ],
};

export const locationIsNeeded: string[] = [
  "4000",
  "4001",
  "4003",
  "4004",
  "4006",
  "4008",
  "4009",
  "4010",
  "4012",
  "4014",
  "4015",
  "4016",
  "4017",
  "4018",
  "4020",
  "4021",
  "4022",
  "4023",
  "4024",
  "4026",
  "4028",
  "4031",
  "4032",
  "4033",
  "4034",
  "4035",
  "4036",
  "4039",
  "4046",
  "4048",
  "4070",
  "4077",
  "4078",
  "4081",
  "4092",
  "4095",
  "4100",
  "4101",
  "4102",
  "4103",
  "4104",
  "4106",
  "4108",
  "4109",
  "4112",
  "4114",
  "4115",
  "4116",
  "4117",
  "4118",
  "4120",
  "4121",
  "4122",
  "4123",
  "4124",
  "4126",
  "4128",
  "4131",
  "4132",
  "4133",
  "4134",
  "4136",
  "4146",
  "4170",
  "4177",
  "4178",
  "4181",
  "4192",
  "4195",
  "4200",
  "4201",
  "4202",
  "4204",
  "4206",
  "4208",
  "4209",
  "4212",
  "4214",
  "4216",
  "4218",
  "4220",
  "4224",
  "4226",
  "4228",
  "4233",
  "4234",
  "4236",
  "4246",
  "4248",
  "4270",
  "4277",
  "4278",
  "4281",
  "4295",
  "4300",
  "4301",
  "4302",
  "4304",
  "4306",
  "4308",
  "4309",
  "4310",
  "4312",
  "4313",
  "4314",
  "4316",
  "4318",
  "4320",
  "4324",
  "4326",
  "4328",
  "4333",
  "4334",
  "4335",
  "4336",
  "4339",
  "4346",
  "4370",
  "4377",
  "4378",
  "4381",
  "4395",
  "4901",
  "4902",
  "4903",
  "4904",
  "4906",
  "4908",
  "4909",
  "4912",
  "4913",
  "4914",
  "4915",
  "4916",
  "4917",
  "4918",
  "4920",
  "4921",
  "4922",
  "4923",
  "4924",
  "4926",
  "4928",
  "4931",
  "4932",
  "4933",
  "4934",
  "4935",
  "4936",
  "4939",
  "4946",
  "4968",
  "4969",
  "4970",
  "4977",
  "4978",
  "4980",
  "4981",
  "4992",
  "4995",
  "4999",
  "5000",
  "5001",
  "5003",
  "5004",
  "5006",
  "5008",
  "5009",
  "5010",
  "5012",
  "5013",
  "5014",
  "5015",
  "5016",
  "5017",
  "5018",
  "5020",
  "5021",
  "5022",
  "5023",
  "5024",
  "5026",
  "5028",
  "5031",
  "5032",
  "5033",
  "5034",
  "5035",
  "5036",
  "5039",
  "5046",
  "5048",
  "5070",
  "5077",
  "5078",
  "5081",
  "5092",
  "5095",
  "5100",
  "5102",
  "5104",
  "5106",
  "5108",
  "5109",
  "5110",
  "5112",
  "5114",
  "5116",
  "5118",
  "5120",
  "5124",
  "5126",
  "5128",
  "5133",
  "5134",
  "5135",
  "5136",
  "5139",
  "5146",
  "5148",
  "5168",
  "5170",
  "5177",
  "5178",
  "5181",
  "5195",
  "5199",
  "5200",
  "5201",
  "5202",
  "5203",
  "5204",
  "5206",
  "5208",
  "5209",
  "5210",
  "5212",
  "5213",
  "5214",
  "5215",
  "5216",
  "5217",
  "5218",
  "5220",
  "5221",
  "5222",
  "5223",
  "5224",
  "5226",
  "5228",
  "5231",
  "5232",
  "5233",
  "5234",
  "5236",
  "5239",
  "5246",
  "5248",
  "5270",
  "5277",
  "5278",
  "5281",
  "5292",
  "5295",
  "5300",
  "5301",
  "5302",
  "5303",
  "5304",
  "5306",
  "5308",
  "5309",
  "5310",
  "5312",
  "5313",
  "5314",
  "5315",
  "5316",
  "5317",
  "5318",
  "5319",
  "5320",
  "5321",
  "5322",
  "5323",
  "5324",
  "5326",
  "5328",
  "5331",
  "5332",
  "5333",
  "5334",
  "5336",
  "5339",
  "5346",
  "5370",
  "5377",
  "5378",
  "5381",
  "5392",
  "5395",
  "5400",
  "5401",
  "5402",
  "5403",
  "5404",
  "5406",
  "5408",
  "5409",
  "5410",
  "5412",
  "5413",
  "5414",
  "5415",
  "5416",
  "5417",
  "5418",
  "5419",
  "5420",
  "5421",
  "5422",
  "5423",
  "5424",
  "5426",
  "5428",
  "5431",
  "5432",
  "5433",
  "5434",
  "5436",
  "5439",
  "5446",
  "5448",
  "5470",
  "5477",
  "5478",
  "5481",
  "5492",
  "5495",
  "5499",
  "5500",
  "5501",
  "5502",
  "5503",
  "5504",
  "5506",
  "5508",
  "5509",
  "5510",
  "5512",
  "5513",
  "5514",
  "5515",
  "5516",
  "5517",
  "5518",
  "5520",
  "5521",
  "5522",
  "5523",
  "5524",
  "5526",
  "5528",
  "5531",
  "5532",
  "5533",
  "5534",
  "5536",
  "5539",
  "5546",
  "5548",
  "5570",
  "5577",
  "5578",
  "5581",
  "5592",
  "5595",
  "5900",
  "5901",
  "5902",
  "5903",
  "5904",
  "5906",
  "5908",
  "5909",
  "5912",
  "5913",
  "5914",
  "5915",
  "5916",
  "5917",
  "5918",
  "5919",
  "5920",
  "5921",
  "5922",
  "5923",
  "5924",
  "5926",
  "5928",
  "5931",
  "5932",
  "5933",
  "5934",
  "5935",
  "5936",
  "5939",
  "5946",
  "5948",
  "5968",
  "5969",
  "5970",
  "5977",
  "5978",
  "5980",
  "5981",
  "5992",
  "5995",
  "5998",
  "5999",
  "6104",
  "6108",
  "6109",
  "6118",
  "6120",
  "6126",
  "6133",
  "6139",
  "6146",
  "6148",
  "6170",
  "6177",
  "6178",
  "6181",
  "6199",
  "6200",
  "6201",
  "6203",
  "6204",
  "6206",
  "6208",
  "6209",
  "6210",
  "6211",
  "6212",
  "6213",
  "6214",
  "6215",
  "6216",
  "6217",
  "6218",
  "6220",
  "6221",
  "6222",
  "6223",
  "6224",
  "6226",
  "6231",
  "6232",
  "6233",
  "6234",
  "6235",
  "6236",
  "6246",
  "6270",
  "6277",
  "6278",
  "6281",
  "6292",
  "6295",
  "6300",
  "6302",
  "6304",
  "6306",
  "6308",
  "6309",
  "6310",
  "6312",
  "6314",
  "6316",
  "6318",
  "6320",
  "6324",
  "6326",
  "6333",
  "6334",
  "6335",
  "6336",
  "6339",
  "6346",
  "6348",
  "6368",
  "6370",
  "6377",
  "6378",
  "6381",
  "6392",
  "6395",
  "6399",
  "7000",
  "7001",
  "7003",
  "7004",
  "7006",
  "7008",
  "7009",
  "7010",
  "7012",
  "7013",
  "7014",
  "7015",
  "7016",
  "7017",
  "7018",
  "7020",
  "7021",
  "7022",
  "7023",
  "7024",
  "7026",
  "7028",
  "7031",
  "7032",
  "7033",
  "7034",
  "7035",
  "7036",
  "7039",
  "7046",
  "7070",
  "7077",
  "7078",
  "7081",
  "7090",
  "7092",
  "7095",
  "7100",
  "7102",
  "7104",
  "7106",
  "7108",
  "7109",
  "7110",
  "7112",
  "7113",
  "7114",
  "7116",
  "7118",
  "7120",
  "7124",
  "7126",
  "7128",
  "7133",
  "7134",
  "7135",
  "7136",
  "7139",
  "7146",
  "7147",
  "7148",
  "7168",
  "7170",
  "7177",
  "7178",
  "7181",
  "7195",
  "7199",
  "7200",
  "7201",
  "7203",
  "7204",
  "7206",
  "7208",
  "7209",
  "7210",
  "7212",
  "7213",
  "7214",
  "7215",
  "7216",
  "7217",
  "7218",
  "7220",
  "7221",
  "7222",
  "7223",
  "7224",
  "7226",
  "7228",
  "7231",
  "7232",
  "7233",
  "7234",
  "7235",
  "7236",
  "7239",
  "7246",
  "7248",
  "7270",
  "7277",
  "7278",
  "7281",
  "7292",
  "7295",
  "7300",
  "7301",
  "7303",
  "7304",
  "7306",
  "7308",
  "7309",
  "7310",
  "7312",
  "7313",
  "7314",
  "7315",
  "7316",
  "7317",
  "7318",
  "7320",
  "7321",
  "7322",
  "7323",
  "7324",
  "7326",
  "7328",
  "7331",
  "7332",
  "7333",
  "7334",
  "7335",
  "7336",
  "7339",
  "7346",
  "7348",
  "7370",
  "7377",
  "7378",
  "7381",
  "7392",
  "7395",
  "7400",
  "7401",
  "7402",
  "7403",
  "7404",
  "7406",
  "7408",
  "7409",
  "7410",
  "7412",
  "7413",
  "7414",
  "7415",
  "7416",
  "7417",
  "7418",
  "7420",
  "7421",
  "7422",
  "7423",
  "7424",
  "7426",
  "7428",
  "7431",
  "7432",
  "7433",
  "7434",
  "7436",
  "7439",
  "7446",
  "7448",
  "7470",
  "7477",
  "7478",
  "7481",
  "7492",
  "7495",
  "7500",
  "7501",
  "7502",
  "7503",
  "7504",
  "7506",
  "7508",
  "7509",
  "7510",
  "7512",
  "7513",
  "7514",
  "7515",
  "7516",
  "7517",
  "7518",
  "7520",
  "7521",
  "7522",
  "7523",
  "7524",
  "7526",
  "7528",
  "7531",
  "7532",
  "7533",
  "7534",
  "7536",
  "7539",
  "7546",
  "7570",
  "7577",
  "7578",
  "7581",
  "7592",
  "7595",
  "7600",
  "7601",
  "7602",
  "7603",
  "7604",
  "7606",
  "7608",
  "7609",
  "7610",
  "7612",
  "7613",
  "7614",
  "7615",
  "7616",
  "7617",
  "7618",
  "7620",
  "7621",
  "7622",
  "7623",
  "7624",
  "7626",
  "7628",
  "7631",
  "7632",
  "7633",
  "7634",
  "7636",
  "7639",
  "7646",
  "7648",
  "7670",
  "7677",
  "7678",
  "7681",
  "7692",
  "7695",
  "7901",
  "7902",
  "7903",
  "7904",
  "7906",
  "7908",
  "7909",
  "7912",
  "7913",
  "7914",
  "7915",
  "7916",
  "7917",
  "7918",
  "7920",
  "7921",
  "7922",
  "7923",
  "7924",
  "7926",
  "7928",
  "7931",
  "7932",
  "7933",
  "7934",
  "7935",
  "7936",
  "7939",
  "7946",
  "7948",
  "7968",
  "7969",
  "7970",
  "7977",
  "7978",
  "7980",
  "7981",
  "7992",
  "7995",
  "7998",
  "7999",
  "9070",
  "9071",
  "9072",
  "9077",
  "9078",
  "9081",
];
